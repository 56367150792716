<template>
  <div>
    <!--begin::Header-->
    <div id="kt_header" style class="header align-items-stretch">
      <!--begin::Container-->
      <div class="container-fluid d-flex align-items-stretch justify-content-between">
        <!--begin::Aside mobile toggle-->
        <div class="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
          <div class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
            id="kt_aside_mobile_toggle">
            <!--begin::Svg Icon | path: icons/duotune/abstract/abs015.svg-->
            <span class="svg-icon svg-icon-2x mt-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                  fill="black" />
                <path opacity="0.3"
                  d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                  fill="black" />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
        </div>
        <!--end::Aside mobile toggle-->
        <!--begin::Mobile logo-->
        <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <a href="./dashboard" class="d-lg-none">
            <img alt="Logo" :src="require('@/assets/images/logo.png')" class="h-20px" />
          </a>
        </div>
        <!--end::Mobile logo-->
        <!--begin::Wrapper-->
        <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          <!--begin::Navbar-->
          <div class="d-flex align-items-stretch" id="kt_header_nav"></div>
          <!--end::Navbar-->
          <!--begin::Topbar-->
          <div class="d-flex align-items-stretch flex-shrink-0">
            <!--begin::Toolbar wrapper-->
            <div class="d-flex align-items-stretch flex-shrink-0">
              <!--begin::Search-->
              <!--end::Search-->
              <!--begin::Activities-->
              <div class="d-flex align-items-center ms-1 ms-lg-3">
                <!--begin::Drawer toggle-->
                <div class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                  id="kt_activities_toggle">
                  <!--begin::Svg Icon | path: icons/duotune/general/gen032.svg-->
                  <span class="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                        fill="black" />
                      <path opacity="0.3"
                        d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z"
                        fill="black" />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                </div>
                <!--end::Drawer toggle-->
              </div>
              <!--end::Activities-->
              <!--begin::Notifications-->
              <!--end::Notifications-->
              <!--begin::Chat-->
              <!--end::Chat-->
              <!--begin::Quick links-->
              <!--end::Quick links-->
              <!--begin::User-->
              

              <div class="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
                <!--begin::Menu wrapper-->
                <div class="cursor-pointer symbol symbol-30px symbol-md-40px" data-kt-menu-trigger="click"
                  data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                  <img src="@/assets/images/avatars/blank.png" alt="user" />
                </div>
                <!--begin::Menu-->
                <div
                  class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                  data-kt-menu="true">
                  <!--begin::Menu item-->
                  <div class="menu-item px-3">
                    <div class="menu-content d-flex align-items-center px-3">
                      <!--begin::Avatar-->
                      <div class="symbol symbol-50px me-5">
                        <img alt="Logo" src="@/assets/images/avatars/blank.png" />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Username-->
                      <div class="d-flex flex-column">
                        <div class="fw-bolder d-flex align-items-center fs-5">
                          UID: {{this.$session.get("logID")}}
                          <!--<spanclass="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span>-->
                        </div>
                        <a href="#"
                          class="fw-bold text-muted text-hover-primary fs-7">{{this.$session.get('logemail')}}</a>
                      </div>
                      <!--end::Username-->
                    </div>
                  </div>
                  <!--end::Menu item-->
                  <!--begin::Menu separator-->
                  <div class="separator my-2"></div>
                  <!--end::Menu separator-->
                  <div class="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start">
                    <a href="#" class="menu-link px-5">
                      <span class="menu-title position-relative">
                        {{$t("header.language")}}
                        <span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                          {{$t('langsec.'+$i18n.locale)}}
                          <img class="w-15px h-15px rounded-1 ms-2"
                            :src="require(`@/assets/images/flags/${$i18n.locale}.svg`)" alt />
                        </span>
                      </span>
                    </a>
                    <!--begin::Menu sub-->
                    <div class="menu-sub menu-sub-dropdown w-175px py-4">
                      <!--begin::Menu item-->
                      <div class="menu-item px-3" v-for="item in this.$i18n.availableLocales" :key="item.id">
                        <a @click.prevent="setLocale(item)" class="menu-link d-flex px-5 active">
                          <span class="symbol symbol-20px me-4">
                            <img class="rounded-1" :src="require(`@/assets/images/flags/${item}.svg`)" alt />
                          </span>{{$t('langsec.'+item)}}
                        </a>
                      </div>
                      <!--end::Menu item-->
                    </div>
                    <!--end::Menu sub-->
                  </div>
                  <!--end::Menu item-->
                  <!--begin::Menu item-->
                  <div class="menu-item px-5 my-1">
                    <a href="#" class="menu-link px-5">{{$t("header.accountsettings")}}</a>
                  </div>
                  <!--end::Menu item-->
                  <!--begin::Menu item-->
                  <div class="menu-item px-5">
                    <a href="#" @click="logout()" class="menu-link px-5">{{$t("header.signout")}}</a>
                  </div>
                  <!--end::Menu item-->
                  <!--begin::Menu separator-->
                  <div class="separator my-2"></div>
                  <!--end::Menu separator-->
                  <!--begin::Menu item-->
                  <div class="menu-item px-5">
                    <div class="menu-content px-5">
                      <label class="form-check form-switch form-check-custom form-check-solid pulse pulse-success"
                        for="kt_user_menu_dark_mode_toggle">
                        <input class="form-check-input w-30px h-20px" type="checkbox" value="1" name="mode"
                          id="kt_user_menu_dark_mode_toggle" data-kt-url="#" />
                        <span class="pulse-ring ms-n1"></span>
                        <span class="form-check-label text-gray-600 fs-7">{{$t("header.darkmode")}}</span>
                      </label>
                    </div>
                  </div>
                  <!--end::Menu item-->
                </div>
                <!--end::Menu-->
                <!--end::Menu wrapper-->
              </div>
              <!--begin::Language-->
              <div class="d-flex align-items-center ms-1 ms-lg-3">
              <div class="dropdown">
                <!--begin::Toggle-->
                <div class="topbar-item" data-toggle="dropdown" data-offset="10px,0px" aria-expanded="false">
                  <div class="btn btn-icon btn-clean btn-dropdown btn-lg me-1 symbol">
                    <img class="h-20px w-20px rounded-sm ms-1"
                      :src="require(`@/assets/images/flags/${$i18n.locale}.svg`)" alt="">
                  </div>
                </div>
                <!--end::Toggle-->
                <!--begin::Dropdown-->
                <div class="dropdown-menu p-0 m-0 dropdown-menu-anim-up dropdown-menu-sm dropdown-menu-right py-5">
                  <!--begin::Nav-->
                  <ul class="navi navi-hover py-4">
                    <!--begin::Item-->
                    <li class="navi-item" v-for="item in this.$i18n.availableLocales" :key="item.id">
                      <a href="#" @click.prevent="setLocale(item)" class="navi-link">
                        <span class="symbol symbol-20 mr-3">
                          <img :src="require(`@/assets/images/flags/${item}.svg`)" :alt="item">
                        </span>
                        <span class="navi-text ms-2 me-10 text-dark">{{$t('langsec.'+item)}}</span>
                      </a>
                    </li>
                    <!--end::Item-->

                  </ul>
                  <!--end::Nav-->
                </div>
                <!--end::Dropdown-->
              </div>
            </div>
              <!--end::Language-->
              <!--end::User -->
            </div>
            <!--end::Toolbar wrapper-->
          </div>
          <!--end::Topbar-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Header-->
    <!--begin::Activities drawer-->
    <div id="kt_activities" class="bg-body" data-kt-drawer="true" data-kt-drawer-name="activities"
      data-kt-drawer-activate="true" data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'300px', 'lg': '900px'}" data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_activities_toggle" data-kt-drawer-close="#kt_activities_close">
      <div class="card shadow-none rounded-0">
        <!--begin::Header-->
        <div class="card-header" id="kt_activities_header">
          <h3 class="card-title fw-bolder text-dark">{{$t("header.activitylogs")}}</h3>
          <div class="card-toolbar">
            <button type="button" class="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_activities_close">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
              <span class="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
                    fill="black" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                </svg>
              </span>
              <!--end::Svg Icon-->
            </button>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body position-relative" id="kt_activities_body">
          <!--begin::Content-->
          <div v-for="(activity, index) in useractivity" :key="index" id="kt_activities_scroll"
            class="position-relative scroll-y me-n5 pe-5" data-kt-scroll="true" data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_activities_body"
            data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer" data-kt-scroll-offset="5px">
            <!--begin::Timeline items-->
            <div class="timeline">
              <!--begin::Timeline item-->
              <div class="timeline-item">
                <!--begin::Timeline line-->
                <div class="timeline-line w-40px"></div>
                <!--end::Timeline line-->
                <!--begin::Timeline content-->
                <div class="timeline-content mb-4 mt-n1">
                  <!--begin::Timeline heading-->
                  <div class="pe-3 mb-1">
                    <!--begin::Description-->
                    <div class="d-flex align-items-center mt-1 fs-6">
                      <!--begin::Info-->
                      <div class="text-muted me-2 fs-7">{{$t("header.date")}}: {{(new
                        Date(activity.user_activity_time*1000)).toLocaleString()}}</div>
                      <!--end::Info-->
                    </div>
                    <!--end::Description-->
                  </div>
                  <!--end::Timeline heading-->
                  <!--begin::Timeline details-->
                  <div class="overflow-auto pb-1">
                    <!--begin::Record-->
                    <div
                      class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-3 py-3 mb-1">
                      <!--begin::Title-->
                      <a href="#"
                        class="fs-8 text-dark text-hover-primary fw-bold w-350px min-w-200px">{{$t("header.process")}}:
                        {{activity.message}}</a>
                      <!--end::Title-->
                      <!--begin::Label-->
                      <!--<div class="min-w-175px pe-2">
                        <span class="badge badge-light text-muted">IP: {{activity.ip}}</span>
                      </div>-->
                      <!--end::Label-->
                      <!--begin::Progress-->
                      <div class="min-w-125px pe-2">
                        <span class="badge badge-light-primary">{{$t("header.device")}}:
                          {{activity.user_browser}}</span>
                      </div>
                      <!--end::Progress-->
                    </div>
                    <!--end::Record-->
                  </div>
                  <!--end::Timeline details-->
                </div>
                <!--end::Timeline content-->
              </div>
              <!--end::Timeline item-->
            </div>
            <!--end::Timeline items-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Body-->
        <!--begin::Footer-->
        <div class="card-footer py-5 text-center" id="kt_activities_footer">
          <a href="#" class="btn btn-bg-body text-primary">
            {{$t("header.viewall")}}
            <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
            <span class="svg-icon svg-icon-3 svg-icon-primary">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                  fill="black" />
                <path
                  d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                  fill="black" />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </a>
        </div>
        <!--end::Footer-->
      </div>
    </div>
    <!--end::Activities drawer-->
  </div>
</template>

<script>
  import DataService from "../../services/DataService";
  export default {

    name: 'locale-changer', 
    data() {
      return {
        langs: this.$i18n.availableLocales,
        useractivity: {}
      }
    },
    methods: {
      setLocale(locale) {
        this.$i18n.locale = locale,
          this.$router.push({
            params: { lang: locale },

          })
        window.location.reload();
      },
      langSession() {
        var pathname = window.location.pathname.split("/"),
          lang = pathname[1];
        var diller = this.$i18n.availableLocales;
        if (!diller.includes(lang) && lang != "account") {
          window.location.href = `/${this.$session.get('lang')}`;
        }
        this.$session.start()
        this.$session.set('lang', lang)
      },
      userSessionControl() {
        const d = new Date();
        let time = d.getTime().toString().substring(0, 10);
        if(this.$session.get("sessionTimeout")<time){
          this.$session.destroy()
          window.location.href = `/`;
        }
        console.log(this.$session.get("sessionTimeout")-time)
        /*DataService.userSessionControl({
          token: this.$session.get("logtoken"),
        }).then((response) => {
          if (response.data.error && response.data.status != "ok") {
            this.$session.destroy()
            window.location.href = `/`;
          }
        }).catch((err) => {
          console.log(err.message)
          this.$session.destroy()
          window.location.href = `/`;
        });*/
      },
      userActivityControl() {
        DataService.userActivityControl({
          token: this.$session.get("logtoken"),
        }).then((response) => {
          if (response) {
            this.useractivity = response.data
          }
        }).catch((err) => {
          console.log(err.message)
        });
      },
      logout() {
        this.$session.destroy()
        //this.$session.set("logtoken", "");
        //this.$session.set("geciciemail", "");
        window.location.href = `/`;
      }
    },
    mounted() {
      setInterval(() => { this.userSessionControl() }, 5000);
      this.langSession();
      this.userSessionControl();
    },
    created() {
      this.userActivityControl();
    }
  }
</script>