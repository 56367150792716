<template>
        <!--begin::Footer-->
          <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
            <!--begin::Container-->
            <div class="
                container-fluid
                d-flex
                flex-column flex-md-row
                align-items-center
                justify-content-between
              ">
              <!--begin::Copyright-->
              <div class="text-dark order-2 order-md-1">
                <span class="text-muted fw-bold me-1">2021©</span>
                <a :href="SITE_URL" target="_blank" class="text-gray-800 text-hover-primary">{{SITE_NAME}}, {{$t("footer.allrightsreserved")}}</a>
              </div>
              <!--end::Copyright-->
              <!--begin::Menu-->
              <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                <li class="menu-item">
                  <a href="#" target="_blank" class="menu-link px-2">{{$t("footer.about")}}</a>
                </li>
                <li class="menu-item">
                  <a :href="`/${this.$i18n.locale}/support`" target="_blank" class="menu-link px-2">{{$t("footer.support")}}</a>
                </li>
                <li class="menu-item">
                  <a v-if="this.$i18n.locale=='tr'" :href="SITE_URL+'/Whitepaper_tr.pdf'" target="_blank" class="menu-link px-2">Whitepaper</a>
                  <a v-if="this.$i18n.locale!='tr'" :href="SITE_URL+'/Whitepaper_en.pdf'" target="_blank" class="menu-link px-2">Whitepaper</a>
                </li>
              </ul>
              <!--end::Menu-->
            </div>
            <!--end::Container-->
          </div>
          <!--end::Footer-->
</template>

<script>
export default {
    name: "Footer",
    data() {
      return {
        SITE_URL: process.env.VUE_APP_SITE_URL,
        SITE_NAME: process.env.VUE_APP_SITE_NAME,
      };
    },
}
</script>